import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MainURL} from './APIContant';

// import { MainURL } from './APIContant';


@Injectable({
    providedIn: 'root'
})
export class HttpmethodsService {
    projectMasterId: any;
    baseUrl = MainURL.HostUrl;
    ProjectCode: any;
    industry: any;
    industryFlag: boolean;

    comapny: string;
    comapnyFlag: boolean;

    constructor(private http: HttpClient) {

    }

    postRequest(url, body) {
        return this.http.post(this.baseUrl + url, body)
    }

    getRequest(url) {
        return this.http.get(this.baseUrl + url)
    }

    putRequest(url, body) {
        return this.http.put(this.baseUrl + url, body)
    }

    deleteRequest(url, id) {
        return this.http.delete(this.baseUrl + url + "/" + id)
    }

}
