import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {HttpmethodsService} from '../Common/httpmethods.service';
import {Industry} from '../mastermodel';

declare var $: any;

@Component({
    selector: 'app-view',
    templateUrl: './view.component.html',
    styleUrls: ['./view.component.scss']
})
export class ViewComponent implements OnInit {
    industry: any;

    constructor(private router: Router, private httpService: HttpmethodsService) {
    }

    searchBox: any;
    industryList: Array<Industry> = [];

    ngOnInit(): void {
        $('#viewMainSearch').on('search', (value) => {
            this.clearData();
        });
        this.get();
    }

    ngAfterViewInit(): void {
        // Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
        // Add 'implements AfterViewInit' to the class.
        setTimeout(() => {
            // tslint:disable-next-line:variable-name
            const modal_backdrop = document.getElementsByClassName('modal-backdrop');
            console.log('modal_backdrop :', modal_backdrop);
            if (modal_backdrop != null && typeof modal_backdrop.length !== 'undefined' && modal_backdrop.length > 0) {
                modal_backdrop[0].remove();
            }
            console.log('modal_backdrop :', modal_backdrop);
        }, 1000);
    }

    // tslint:disable-next-line:typedef
    get() {

        this.httpService.getRequest('hexacorp/getFilters').subscribe((result: any) => {
            console.log('lof', result.data.items);
            this.industryList = result.data.items.classification.map(name => ({ name}));
            this.searchBox = '';
        });
    }

    getindustry(Industry) {
        const ind = this.httpService.industry = Industry;
        console.log('ind', ind);
        this.httpService.industryFlag = true;
        this.router.navigateByUrl('/details');
    }

    // tslint:disable-next-line:typedef
    clearData() {

        this.searchBox = '';
    }
}
