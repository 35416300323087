import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PagingComponent } from './paging/paging.component';
import { SharedRoutingModule } from './shared-routing.module';



@NgModule({
  declarations: [PagingComponent],
  imports: [
    CommonModule,
    SharedRoutingModule,
  ],
  exports:[
    PagingComponent
  ]
})
export class SharedModule { }
