<div class="row" style="margin-left:0px !important;margin-right:0px !important ;margin-top: 0%;display: -webkit-inline-box;width: 100%;position: fixed;background: white;z-index: 999;box-shadow:0px 0px 4px 0px #ea442a;">
  <div class="col-sm-3 col-xs-3 col-md-3" style="border-left: 1px solid lightgray;">
    <img src="assets/images/logo.png" style="padding-top: 13px;width:200px" class="form-group mainLogo">
  </div>
  <!-- <img src="assets/images/image 70.png" class="form-group mainLogo"><img src="assets/images/HEXA.png" style="margin-top: 1%;">&nbsp;<img src="assets/images/CORP.png" style="margin-top: 1%;">  -->
  <div class="col-md-9 col-sm-9 col-xs-9" style="bottom: -6px;">
    <div class="input-icons" style="margin-left: 0px !important;">
      <i class="fa fa-search icon"></i>
      <input class="input-field form-control searchBox"
      id="viewMainSearch"
             type="search"
             [(ngModel)]="searchBox"
             placeholder="Search"

             style="background: #F4F9FF;border-radius: 10px;width: 80%;height: 45px;border: 1px solid #F4F9FF;" >
             <!-- <i class="fa fa-times icon2" (click)="clearData()"></i> -->
  </div>
  </div>
</div>
<div class="row" style="padding-top: 7%;">
  <div class="col-sm-12">
    <!-- <div class="col-sm-12" style="margin-top: 0%;display: -webkit-inline-box;">
        <img src="assets/images/logo.png" style="padding-top: 13px;width:200px" class="form-group mainLogo"> -->
      <!-- <img src="assets/images/image 70.png" class="form-group mainLogo"><img src="assets/images/HEXA.png" style="margin-top: 1%;">&nbsp;<img src="assets/images/CORP.png" style="margin-top: 1%;">  -->
      <!-- <input type="text" [(ngModel)]="searchBox" style="background: #F4F9FF;border-radius: 10px;width: 56%;height: 45px;margin-left: 10%;" class="forn-control searchBox" placeholder="Search"> -->





    <!-- </div> -->
    <!-- <hr> -->
    <div class="row">
      <div class="col-sm-12">
        <div class="container">
          <div class="row form-group" style="margin-top: 20px;">
            <span class="iIcon1"></span> <img src="assets/images/Path 41 Copy.png" style="height: 15px;margin-top: 5px;margin-left: 25px;">&nbsp;<img src="assets/images/Combined Shape.png" style="height: 15px;margin-top: 5px;"> <span class="mainTitle">All Industries</span>
          </div>

          <div class="row">
            <div *ngFor="let industry of industryList | filter : searchBox" class="col-sm-2 mb mainRectangle form-group" >
                <div class="col-sm-12 innerRectangle">
                  <span class="mainHeading">
                     {{industry.name}}
                      </span>
                  <span class="viewAllButton" (click)="getindustry(industry.name)">View All</span>
                </div>
          </div>
            <!-- <div class="col-sm-2 mb mainRectangle" >
              <div class="col-sm-12 innerRectangle">
                <span class="mainHeading">Advertising Services</span>
                <span class="viewAllButton">View All</span>
              </div>
          </div>

          <div class="col-sm-2 mb mainRectangle" >
            <div class="col-sm-12 innerRectangle">
              <span class="mainHeading">Advertising Services</span>
              <span class="viewAllButton">View All</span>
            </div>
        </div>

        <div class="col-sm-2 mb mainRectangle" >
          <div class="col-sm-12 innerRectangle">
            <span class="mainHeading">Advertising Services</span>
            <span class="viewAllButton">View All</span>
          </div>
      </div>

      <div class="col-sm-2 mb mainRectangle" >
        <div class="col-sm-12 innerRectangle">
          <span class="mainHeading">Advertising Services</span>
          <span class="viewAllButton">View All</span>
        </div>
    </div> -->
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
