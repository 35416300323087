<div class="row"
     style="margin-left:0px !important;margin-right:0px !important ;margin-top: 0%;display: -webkit-inline-box;width: 100%;position: fixed;background: white;z-index: 999;box-shadow:0px 0px 4px 0px #ea442a;top: 0;height: 85px;">
    <div class="col-sm-3 col-xs-3 col-md-3" style="border-left: 1px solid lightgray;width: 30%;">
        <img src="assets/images/logo.png" style="padding-top: 13px;width:200px; cursor: pointer;"
             class="form-group mainLogo" routerLink="/view" routerDirection="backward">
    </div>
    <div class="col-md-9 col-sm-9 col-xs-9" style="bottom: -6px;width: 70%;">
        <div class="input-icons">
            <i class="fa fa-search icon" style="color:#ea442a !important;;"></i>

            <input class="input-field form-control searchBox" type="search" (keyup)="searchAndFilter('false','dummy')"
                   [(ngModel)]="name" name="name" id="overAllSearch" placeholder="Search"
                   style="background: #F4F9FF;border-radius: 10px;height: 45px;border: 1px solid #F4F9FF;">

        </div>
    </div>
</div>
<div class="row" style="padding-top: 90px;">
    <div class="col-sm-12">

        <!-- <hr> -->
        <div class="row">
            <div class="col-sm-12" style="margin-top: 20px;">
                <div class="row" id="FilterDiv" style="padding-left: 20px;">
                    <div class="col-md-3">
                        <div class="row">
                            <div class="col-md-1"></div>
                            <div class="col-md-5 filter" style="width: 45% !important;">Filters</div>
                            <div (click)="clear()"
                                 style="cursor: pointer;text-align: -webkit-right; width: 45% !important;"
                                 class="col-md-5 clear">clear all
                            </div>
                        </div>
                        <hr style="margin-left: 16px" class="horline">
                        <div class="row">
                            <div class="col-md-1"></div>
                            <div class="col-md-5 filter">Region</div>
                            <div class="col-md-5" (click)="displaySearchBar(true)"
                                 style="text-align: -webkit-right;width: 45%;">
                                <i class="fa fa-search" [hidden]="isDisplaySearchBar" aria-hidden="true"></i>
                            </div>
                            <div class="col-md-12" [hidden]="!isDisplaySearchBar">
                                <input class="input-field forn-control searchBox" type="search"
                                       [(ngModel)]="countrySearch" name="name"
                                       id="countrySearch" placeholder="Search"
                                       style="background: #F4F9FF;border-radius: 10px;height: 45px;width:95%;border: 1px solid #F4F9FF;margin-left: 2%;">
                            </div>
                        </div>
                        <div class="container" style="margin-top: 20px;">
                            <form>
                                <div *ngFor="let country of countryList | filter : countrySearch;let i = index">
                                    <label class="container1 labelch">
                                        {{country.name}}
                                        <input class="myCheckbox" [checked]="country.isChecked" name="industryCheckBox"
                                               type="checkbox"
                                               (change)="country.isChecked = !country.isChecked;advancedSearchAndFilter()">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </form>
                        </div>
                        <hr style="margin-left: 16px" class="horline">
                        <div class="row">
                            <div class="col-md-1"></div>
                            <div class="col-md-5 filter">Categories</div>
                            <div class="col-md-5" (click)="displaySearchBar1(true)"
                                 style="text-align: -webkit-right;width: 45%;">
                                <i class="fa fa-search" [hidden]="isDisplaySearchBar1" aria-hidden="true"></i>
                            </div>
                            <div class="col-md-12" [hidden]="!isDisplaySearchBar1">
                                <input class="input-field forn-control searchBox" type="search"
                                       [(ngModel)]="categorySearch" name="name"
                                       id="categorySearch" placeholder="Search"
                                       style="background: #F4F9FF;border-radius: 10px;height: 45px;width:95%;border: 1px solid #F4F9FF;margin-left: 2%;">
                            </div>
                        </div>
                        <div class="container" style="margin-top: 20px;">
                            <form>
                                <div *ngFor="let category of filteredCategories | filter : categorySearch;let i = index">
                                    <label class="container1 labelch">
                                        {{category.name}}
                                        <input class="myCheckbox" [checked]="category.isChecked" name="industryCheckBox"
                                               type="checkbox"
                                               (change)="category.isChecked = !category.isChecked;advancedSearchAndFilter()">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </form>
                        </div>
                        <hr style="margin-left: 16px" class="horline">
                        <div class="row">
                            <div class="col-md-1"></div>
                            <div class="col-md-5 filter" style="width: 45%;">Industries &nbsp; &nbsp;</div>
                            <div class="col-md-5" (click)="displaySearchBar2(true)"
                                 style="text-align: -webkit-right;width: 45%;">
                                <i class="fa fa-search" [hidden]="isDisplaySearchBar2" aria-hidden="true"></i>
                            </div>
                            <div class="col-md-12" [hidden]="!isDisplaySearchBar2">
                                <input class="input-field forn-control searchBox" type="search"
                                       [(ngModel)]="industrySearch" name="name"
                                       id="industrySearch" placeholder="Search"
                                       style="background: #F4F9FF;border-radius: 10px;height: 45px;width:95%;border: 1px solid #F4F9FF;margin-left: 2%;">
                            </div>
                        </div>
                        <div class="container" style="margin-top: 20px;">
                            <form style="overflow-y: scroll;height: 65vh;" *ngIf="industryList">
                                <div *ngFor="let industry of industryList | filter : industrySearch;let i = index">
                                    <label class="container1 labelch">
                                        {{ industry.name }}
                                        <input class="myCheckbox" [checked]="industry.isChecked" name="industryCheckBox"
                                               type="checkbox"
                                               (change)="industry.isChecked = !industry.isChecked; advancedSearchAndFilter()">
                                        <span class="checkmark"></span>
                                    </label>

                                </div>
                            </form>
                            <label class="more" style="display: none">
                                <i class="fa fa-plus" aria-hidden="true"></i> 40 more</label>
                        </div>
                    </div>
                    <div style="border-right: 1px solid #E0E0E0;
            height: 120vh;
            margin-top: -1.2%;margin-left: -1px;"></div>
                    <div class="col-md-9">
                        <div class="row">
                            <span class="iIcon1"></span>
                                <img src="assets/images/Path 41 Copy.png"
                                     style="height: 15px;margin-top: 5px;margin-left: 25px;">&nbsp;
                                <img src="assets/images/Combined Shape.png" style="height: 15px;margin-top: 5px;">
                            <span class="mainTitle">
                                <span style="cursor: pointer;" routerLink="/view">Home</span> > All Companies</span>
                        </div>
                        <br>
                        <div id="parent">
                            <table class="table">
                                <thead style="border-collapse: collapse; box-shadow: 0 0 0 1px #E5E5E5">
                                <tr>
                                    <th scope="col">S.no</th>
                                    <th scope="col">Company Name</th>
                                    <th scope="col">Founded</th>
                                    <th scope="col">Industry</th>
                                    <th scope="col">Country</th>
                                    <th scope="col">Status</th>
                                    <th scope="col"></th>
                                </tr>
                                </thead>
                                <tbody>


                                <tr *ngFor="let item of companyList | filter : searchBox;let i = index" class="table-text">
                                    <td>{{i + 1 + (page - 1) * (pageSize)}}</td>
                                    <td>{{item.companyName}}</td>
                                    <td>{{ item.founded ? (item.founded | date:'yyyy') : '-' }}</td>
                                    <td>{{item.classification}}</td>
                                    <td>{{item.country || '-'}}</td>
                                    <td>{{item.status || '-'}}</td>
                                    <td>
                                        <u style="color:orangered;cursor: pointer;" data-toggle="modal" data-target="#myModal2" (click)="GetById(item._id)">See Details</u>
                                    </td>
                                </tr>
                                </tbody>

                            </table>
                            <br><br>
                            <app-paging [totalRecords]="totalRecords" (gotoPage)="gotoPage($event)" [pageSize]="pageSize"></app-paging>
<!--                            {{ |json }}-->
                            <div *ngIf="companyList?.length === 0">
                                <p style="text-align: center">No data available. Please try again later.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal right fade" id="myModal2" tabindex="-1" role="dialog" aria-labelledby="myModalLabel2">
    <div class="modal-dialog" role="document" style="width: 90%;">
        <div class="modal-content">

            <div class="modal-header">
                <h4 class="modal-title" id="myModalLabel2" style="color: orangered;font-size: 15px">
                    <b>{{company.companyName}}</b>
                </h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>

            </div>

            <div class="modal-body">
                <div class="head">
                    <div class="row">

                        <div class="col-md-7" style="padding-left: 5%;padding-top: 2%;">
                            <label class="modalHead">Business Profile</label>
                            <p class="para">{{company.profile}}</p>
                        </div>
                        <div class="col-md-5">
                            <div class="mainRectangle">
                                <div class="col-sm-10 innerRectangle">
                                      <span class="mainHeading">
                                        {{(company.classification | slice:0:50) + (getCompanyCategoryLength(company) ? '..' : '')}}
                                      </span>
                                    <span class="viewAllButton" data-dismiss="modal" (click)="goToCompany(company.classification)">ViewAll</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-sm-12">
                        <table>
                            <tr *ngIf="company.companyCIN">
                                <th class="subhead">Registration No.</th>
                                <td class="subpara"> {{company.companyCIN}}</td>
                            </tr>
                            <tr *ngIf="company.dateOfInc">
                                <th class="subhead">Founded</th>
                                <td class="subpara"> {{company.dateOfInc | date:'yyyy'}}</td>
                            </tr>
                            <tr *ngIf="company.companyType">
                                <th class="subhead">Status</th>
                                <td class="subpara"> {{company.companyType}}</td>
                            </tr>
                            <tr *ngIf="company.website">
                                <th class="subhead">Website</th>
                                <td class="subpara"> {{company.website}}</td>
                            </tr>
                            <tr *ngIf="checkifProducts(company)">
                                <th class="subhead">Products</th>
                                <td class="subpara"> {{company.companyCategory}}
                                    <ng-container *ngFor="let prod of company.products;let subindex = index">
                                        <span> {{prod.name}} {{company.products.length != subindex + 1 ? ',' : ''}}</span>
                                    </ng-container>
                                </td>
                            </tr>
                            <tr *ngIf="company.city">
                                <th class="subhead">City</th>
                                <td class="subpara"> {{company.city}}</td>
                            </tr>
                            <tr *ngIf="this.company.line1">
                                <th class="subhead">Address</th>
                                <td class="subpara"> {{this.company.line1}}
                                </td>
                                <!-- {{this.company.postCode}}, {{this.company.line2}}, {{this.company.city}}, {{this.company.state}}, {{this.company.country}}, -->
                            </tr>
                        </table>
                    </div>
                </div>
                <br>
                <div class="row" style="margin-inline-start: 16px !important;margin-inline-end: 16px !important;">
                    <div class="col-md-2"></div>
                    <div class="col-sm-8 bottompara">
                        <a href="https://hexatp.com/contact/" target="_blank" rel="noopener noreferrer">
                            <p class="bottomtitle">Looking for comparable companies? Contact Us to Know More </p>
                        </a>
                    </div>
                    <div class="col-md-2"></div>
                </div>
            </div>
        </div>
        <!-- modal-content -->
    </div>
    <!-- modal-dialog -->
</div>
<!-- modal -->
