import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpmethodsService} from '../Common/httpmethods.service';
import {Company, Industry, Category, Country} from '../mastermodel';
import {PAGESIZE} from '../Common/APIContant';
import {ViewComponent} from '../view/view.component';
import {element} from "protractor";

import {reduce} from "rxjs/operators";
import {any} from "codelyzer/util/function";

declare var $: any;

@Component({
    selector: 'app-details',
    templateUrl: './details.component.html',
    styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {
    companyList: Array<Company>;
    countryList: Array<Country> = [];
    filteredCategories: any[] = [];
    filterCountries: any[] = [];
    filterIndustries: any[] = [];

    Manufacturing: boolean = false;
    showNoRecordMessage = false;
    Trading: boolean = false;
    Malaysia: boolean = false;
    Southeast: boolean = false;
    Services: boolean = false;
    page: number = 1;
    pageSize: number = PAGESIZE;
    totalRecords: number = 0;
    showRecordCount: boolean = true;
    industryClassificationCode: any;
    filterFlag: boolean = false;
    name: string;
    industrySearch: string = '';
    categorySearch: string = '';
    countrySearch: string = '';
    company: Company = new Object();
    industryList: Array<Industry> = [];
    categoryList: Array<Category> = [];
    searchBox: any;
    ViewComponent: ViewComponent;
    checkedbox: boolean = false;
    industry: any;
    AdvancedSearchString: any = '';
    AdvancedSearchCategoryString: string = '';
    AdvancedSearchRegionString: string = '';
    industryFlagFlag: boolean;
    showRecordDetails: boolean = true;
    noRecordDetails: boolean = false;
    isDisplaySearchBar: boolean = false;
    isDisplaySearchBar1: boolean = false;
    isDisplaySearchBar2: boolean = false;

    constructor(private router: Router, private httpService: HttpmethodsService, private params: ActivatedRoute) {
        this.industry = this.httpService.industry;
        this.industryFlagFlag = this.httpService.industryFlag;
    }

    displaySearchBar(value) {
        this.isDisplaySearchBar = value;
    }
    displaySearchBar1(value) {
        this.isDisplaySearchBar1 = value;
    }
    displaySearchBar2(value) {
        this.isDisplaySearchBar2 = value;
    }


    ngOnInit(): void {
        this.getCatList();
        this.getCountry();
        var parent = document.getElementById('parent');
        if (parent != null) {
            parent.addEventListener('mousewheel', () => {
            });
        }
        $('#overAllSearch').on('search', (value) => {
            this.searchAndFilter('false', 'dummy');
        });
        $('#industrySearch').on('search', (value) => {
            this.industrySearch = '';
            this.displaySearchBar(false);
        });
        const urlclassification: string = this.params.snapshot.queryParamMap.get('classification');
        const urlCategory: string = this.params.snapshot.queryParamMap.get('category');
        const urlIndRegion: string = this.params.snapshot.queryParamMap.get('countryOfInc');
        console.log('urlIndRegion :', urlIndRegion);
        const urlSearchName: string = this.params.snapshot.queryParamMap.get('search');
        if (urlclassification != null) {
            this.industry = urlclassification;
            this.industryFlagFlag = true;
        }
        if (urlSearchName != null) {
            this.name = urlSearchName;

        }
        //searchicon show

    }

    // ngAfterContentChecked(): void {


    // }
    ngAfterViewInit(): void {

        this.getIndList();
        // this.getActiveIndustryCategoryString();
        this.get();
    }

    clear() {
        this.checkedbox = false;
        this.filterFlag = false;
        this.industry = undefined;
        this.industryFlagFlag = false;
        this.Manufacturing = false;
        this.Trading = false;
        this.Services = false;
        this.AdvancedSearchString = '';
        this.name = '';
        $('.myCheckbox').prop('checked', false);
        // this.get();
        this.get();
        this.setIndustryCheckedOrUnchecked(true);
        this. filterCategories();
        this.filterCountry();
        // this.setCategoryCheckedOrUnchecked(true);
    }

    get(page = 0) {
        this.page = page > 0 ? page : this.page;
        let indCategory = (typeof this.AdvancedSearchCategoryString != 'undefined' && this.AdvancedSearchCategoryString != '' && this.AdvancedSearchCategoryString != null) ? this.AdvancedSearchCategoryString : '';
        let indRegion = (typeof this.AdvancedSearchRegionString != 'undefined' && this.AdvancedSearchRegionString != '' && this.AdvancedSearchRegionString != null) ? this.AdvancedSearchRegionString : '';
        let searchString = this.name != undefined && this.name != '' && this.name != null ? '&companyName=' + this.name : '';
        if (this.industryFlagFlag) {
            // relationship between industry and company page
            this.httpService.getRequest('hexacorp/getCompanies?perPage=' + this.pageSize + '&page=' + this.page + '&classification=' + this.industry + indCategory + searchString + indRegion).subscribe((result: any) => {
                if (result.data.items.length > 0) {
                    this.showRecordDetails = true;
                    this.noRecordDetails = false;
                    this.companyList = result.data.items;
                    console.log('0', this.companyList);
                    if (result.data.pagination.total) {
                        this.totalRecords = result.data.pagination.total;
                    }
                } else {
                    this.showRecordDetails = false;
                    this.noRecordDetails = true;
                    this.totalRecords = 0;
                    // alert('Please select an industry on the left side or search with company name on top');
                }


            })
        } else {
            this.httpService.getRequest('hexacorp/getCompanies?perPage=' + this.pageSize + '&page=' + this.page + indCategory + searchString + indRegion).subscribe((result: any) => {
                console.log('as', result);
                if (result.data.items.length > 0) {
                    this.showRecordDetails = true;
                    this.noRecordDetails = false;
                    this.companyList = result.data.items;
                    if (result.data.pagination.total) {
                        this.totalRecords = result.data.pagination.total;
                    }
                } else {
                    this.showRecordDetails = false;
                    this.noRecordDetails = true;
                    this.totalRecords = 0;
                    // alert('Please select an industry on the left side or search with company name on top');
                }
            });
        }
    }

    gotoPage(page: number) {
        // debugger;
        this.page = page;
        this.advancedSearchAndFilter(page);

    }

    getIndList() {
        this.httpService.getRequest('hexacorp/getFilters').subscribe((result: any) => {
            console.log('result12', result);
            if (result.data.items.classification) {
                this.industryList = result.data.items.classification.map(name => ({ name, isChecked: false }));
                this.setIndustryCheckedOrUnchecked();

            }
        });
    }
    getCatList() {
        this.httpService.getRequest('hexacorp/getFilters').subscribe((result: any) => {
            if (result.data.items.categories) {
                this.categoryList = result.data.items.categories.map(name => ({ name, isChecked: false }));
                this.filteredCategories = this.categoryList;
            }
        });
    }

    getCountry() {
        this.httpService.getRequest('hexacorp/getFilters').subscribe((result: any) => {
            if (result.data.items.countries) {
                this.countryList = result.data.items.countries.map(name => ({name, isChecked: false}));
            }
        });
    }

    tempVar: number = 0;
    tempFlag: boolean = false;
    str: string;
    tempList: Array<string> = [];
    tempVal: number = 0;

    GetById(id: string) {
        this.httpService.getRequest('hexacorp/getCompany/' + id).subscribe((result: any) => {
            this.company = result.data.items;
            this.company.classification = result.data.items.classification;
            this.company.line1 = result.data.items.address;
            this.company.city = result.data.items.city;
            this.company.country = result.data.items.country;


        })
        $('#myModal2').modal('show');
    }

//  getdetails(){
//   this.httpService.getRequest('company?search=' + id).subscribe((result: any) => {

//   })
//  }

    clearData() {
        this.name = '';
        // this.Filter();
    }

    async goToCompany(classification) {
        // alert('hiding modal');
        await $('#myModal2').modal('hide');
        // this.httpService.comapny = classification;
        // this.httpService.comapnyFlag = true;
        // this.router.navigateByUrl('/view');
        this.setIndustryCheckedOrUnchecked(true);
        this.filterCategories();
        this.filterCountry();
        // this.setCategoryCheckedOrUnchecked(true);

        this.industry = classification;

        this.industryFlagFlag = true;

        this.getIndList();

        var modal_backdrop = document.getElementsByClassName('modal-backdrop');
        setTimeout(() => {
            if (modal_backdrop != null && typeof modal_backdrop.length != 'undefined' && modal_backdrop.length > 0) {
                for (let index = 0; index < modal_backdrop.length; index++) {
                    modal_backdrop[index].remove();

                }

            }
        }, 500);
        this.get(1);


    }

    removeKNumber: number
    removeKValue: boolean = false;


    async searchAndFilter(flag, classification) {

        if (flag) {
            this.filterFlag = true;
        }
        await this.advancedSearchAndFilter();
     if (this.name != '' && typeof this.name != 'undefined' && (typeof this.AdvancedSearchString === 'undefined' || this.AdvancedSearchString === '' || this.AdvancedSearchString === null)) {
            // alert('intial search with only name');
            this.httpService.getRequest('hexacorp/getCompanies?companyName=' + this.name).subscribe((result: any) => {
                this.companyList = result.data.items;
                if (result.data.pagination.total) {
                    this.totalRecords = result.data.pagination.total;
                }
            });
        } else {
            // alert('intial search with blank');
            this.get();
        }
    }

    getCompanyCategoryLength(data) {
        if (typeof data != 'undefined' && typeof data.classification != 'undefined' && typeof data.classification.length != 'undefined' && data.classification.length > 40) {
            return true;
        } else {
            return false;
        }
    }

    ngOnDestroy(): void {
        var modal_backdrop = document.getElementsByClassName('modal-backdrop');
        if (modal_backdrop != null && typeof modal_backdrop.length != 'undefined' && modal_backdrop.length > 0) {
            modal_backdrop[0].remove();
        }

    }
    async advancedSearchAndFilter(page = 1) {
        // filter categories, industries, and countries
        this.filterCategories();
        this.filterIndustry();
        this.filterCountry();

        // get selected filter values
        let checkedCategories = this.categoryList.filter(category => category.isChecked);
        let checkedIndustries = this.industryList.filter(industry => industry.isChecked);
        let checkedCountry = this.countryList.filter(country => country.isChecked);
        let parameters = [];

        if (checkedCategories.length > 0) {
            // Push each category name as a separate parameter
            checkedCategories.forEach(category => {
                parameters.push('category=' + encodeURIComponent(category.name));
            });
        }

        if (checkedIndustries.length > 0) {
            // Push each industry name as a separate parameter
            checkedIndustries.forEach(industry => {
                parameters.push('classification=' + encodeURIComponent(industry.name));
            });
        }

        if (checkedCountry.length > 0) {
            // Push each country name as a separate parameter
            checkedCountry.forEach(country => {
                parameters.push('country=' + encodeURIComponent(country.name));
            });
        }
        let searchString = this.name != undefined && this.name != '' && this.name != null ? '&companyName=' + this.name : '';

// Join the parameters using the ampersand separator
        this.AdvancedSearchString = 'hexacorp/getCompanies?' + parameters.join('&') + '&perPage=' + this.pageSize + '&page=' + page + searchString;
        // make API request and process response
        this.httpService.getRequest(this.AdvancedSearchString).subscribe((result: any) => {
                this.showRecordDetails = true;
                this.noRecordDetails = false;
                this.companyList = result.data.items;
                if (result.data.pagination.total) {
                    this.totalRecords = result.data.pagination.total;
                }
        });
    }

    filterCategories() {
        if (!this.categorySearch) {
            this.filteredCategories = this.categoryList;
        } else {
            this.filteredCategories = this.categoryList.filter(category => category.name
                .toLowerCase().includes(this.categorySearch.toLowerCase()));
        }
    }
    filterCountry() {
        if (!this.countrySearch) {
            this.filterCountries = this.categoryList;
        } else {
            this.filterCountries = this.countryList.filter(country => country.name
                .toLowerCase().includes(this.categorySearch.toLowerCase()));
        }
    }
    filterIndustry() {
        if (!this.industrySearch) {
            this.filterIndustries = this.industryList;
        } else {
            this.filterIndustries = this.industryList.filter(industry => industry.name
                .toLowerCase().includes(this.industrySearch.toLowerCase()));
        }
    }


    setIndustryCheckedOrUnchecked(isClearAll = false) {
        if (isClearAll) {
            this.industryList.forEach((element) => {
                element.isChecked = false;
            });
        } else {
            this.industryList.forEach((element) => {
                if (typeof this.industry !== 'undefined' && this.industry !== null && this.industry === element.name) {
                    element.isChecked = true;
                } else {
                    element.isChecked = false;
                }
            });
        }
    }


    checkifProducts(company) {
        if (typeof company.products != 'undefined' && typeof company.products.length != 'undefined' && company.products.length > 0) {
            return true;
        } else {
            return false;
        }
    }
}
